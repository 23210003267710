import { GatsbySeo } from 'gatsby-plugin-next-seo'
import PartnerBenefitsDecathlon from 'src/components/ClubComponents/PartnerBenefitsDecathlon'

const ClubeDecathlonPartners = () => {
  return (
    <>
      <GatsbySeo title="Clube | Decathlon | Parceiros" />
      <PartnerBenefitsDecathlon />
    </>
  )
}

export default ClubeDecathlonPartners
